<template>
  <div class="mt-20 recordingGrid">
    <!--   allow-selection  -->
    <contact-grid
      ref="gridRef"
      :showHeader="false"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :items="items"
      :btn-operation="btnOperation"
      :url-params="parmas"
      :scroll="{ x: 1100, y: gridHeight }"
      @dataChange="dataChange"
      @fnGrid="handleGrid"
    >
      <!--      @Schema(name = "goldSource", description = "金币来源    1-注册 2-邀请 3-充值 4-多充多得 5-完成指引 6-扣减金币")-->
      <!--      @TableField(value = "gold_source")-->
      <!--      @NotNull(message = "金币来源    1-注册 2-邀请 3-充值 4-多充多得 5-完成指引 6-扣减金币不能为空")-->
      <!--      private Integer goldSource;-->

      <template v-slot:goldSource="{ record }">
        <div v-if="record.goldSource === 5">完成指引</div>
        <div v-if="record.goldSource === 2">邀请注册</div>
      </template>
      <!--  goldSource  createTime  -->
      <!--  获得金币   -->
      <template v-slot:goldNum="{ record }">
        <div>获得{{ record.goldNum }}金币</div>
      </template>

      <!--    处理创建时间   -->
      <template v-slot:createTime="{ record }">
        <div>
          {{ handleTime(record) }}
        </div>
      </template>
    </contact-grid>

    <div class="tooltip">
      <a-tooltip placement="right">
        <template #title>
          <div>1.邀请的好友注册人脉宝盒账号您和好友双方均可获得50金币。</div>
          <div>
            2.邀请的好友注册人脉宝盒账号并完成新手指引，您和好友双方均可获得100金币。
          </div>
          <div>
            3.每成功邀请一位好友完成注册和新手指引，您与好友均可获得最多150金币。
          </div>
          <div>
            4.以上金币奖励适用每个有效用户，邀请好友越多获得的金币奖励越多。
          </div>
          <!--                <div>-->
          <!--                  5.-->
          <!--                  以上金币获取方法适用每个有效用户，邀请好友越多金币返佣越多。-->
          <!--                </div>-->
        </template>
        <div class="guize activeColor">
          <QuestionCircleOutlined class="activeColor" />
          规则说明
        </div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import contactGrid from '@/components/contactGrid';
import { reactive, toRefs } from 'vue';
import playApi from '@/api/play';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { Tooltip } from 'ant-design-vue';
import { useStore } from 'vuex';
// import contactsCircleApi from "@/api/contactsCircle";

export default {
  components: {
    contactGrid,
    ATooltip: Tooltip,
    QuestionCircleOutlined,
  },

  setup() {
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      parmas: {
        userId: userId,
        conditionDto: {
          sortMap: {
            field: '',
            sort: '',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
    });

    return {
      ...toRefs(state),
      url: playApi.pageUrl,
      gridHeight: document.body.clientHeight - 386,

      items: [
        {
          key: 'data',
          label: '搜索日期',
          type: 'data',
          dataset: '',
        },
        {
          key: 'goldSource',
          label: '筛选',
          type: 'select',
          dataset: [
            { value: '2', label: '邀请注册', id: '2' },
            { value: '5', label: '完成指引', id: '3' },
          ],
        },
      ],

      columns: [
        { dataIndex: 'askedPhone', title: '', width: 120, ellipsis: true },
        {
          dataIndex: 'goldSource',
          title: '',
          width: 100,
          ellipsis: true,
          slots: { customRender: 'goldSource' },
        },
        {
          dataIndex: 'goldNum',
          title: '',
          width: 100,
          ellipsis: true,
          slots: { customRender: 'goldNum' },
        },
        //
        //   //   最后互动时间 last_interaction_time
        {
          dataIndex: 'createTime',
          title: '',
          width: 100,
          // fixed: 'right',
          ellipsis: true,
          slots: { customRender: 'createTime' },
        },
      ],

      btnOperation: [
        // {
        //   type: 'selectAll',
        //   label: '全选',
        //   permission: 'resources:selectAll',
        //   fnClick: () => {
        //     // console.log('全选', gridRef.value.getSelectedRowsKeys());
        //   },
        // },
      ],
      // 父子传值
      handleGrid() {},
      // 处理日期
      handleTime(record) {
        if (record.createTime) {
          let datePart = record.createTime.substring(0, 10);
          return datePart;
        } else {
          return '';
        }
      },
    };
  },
};
</script>

<style scoped lang="less">
.recordingGrid {
  position: relative;
}
//.recordingGrid /deep/ .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker {
//  width: 60%;
//}

.tooltip {
  position: absolute;
  top: 0px;
  left: 0px;
  //.text {
  //  width: 388px;
  //}
}
</style>
